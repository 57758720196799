import { createRouter,createWebHashHistory } from "vue-router";

const router=createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:'/',
            component: ()=>import('../pages/home/Home')
        },

        {
            path:'/home',
            component: ()=>import('../pages/home/Home')
        },
        {
            path:'/cart',
            component: ()=>import('../pages/aboutUs/AboutUs')
        },
        {
            path:'/guaran',
            component: ()=>import('../pages/guarantee/Guarantee')
          
        },
        {
            path:'/version',
            component: ()=>import('../pages/verification/Verification')
        },
        {
            path:'/login',
            component: ()=>import('../pages/login/Login')
        },
        {
            path:'/sys',
            component: ()=>import('../pages/system/System'),
            meta:{isAuto :true}
        },
        {
            path:'/result',
            component:()=>import('../pages/result/Result')
        },
        {
            path:'/file',
            component:()=>import('../pages/file/Filedownload')
        },
        {
            path:'/filedow',
            component:()=>import('../pages/file/Filedow')
        }
    ]

    
    
})
router.beforeEach((to, from, next) => {
    if(to.meta.isAuto){
         if(localStorage.getItem('islogin')==='1'){
             next();
         }else{
             alert('请先登录')
         }
    }else{
     next();
    }
 })
export default router