import { createApp } from 'vue'
import App from './App.vue'
import '@/rem/rem.js'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import router from './router';
import './common/css/base.less'
import vuex from './store'

const app=createApp(App)
app.use(router)
app.use(ElementPlus, {locale: zhCn})
app.use(vuex)
app.mount('#app')
