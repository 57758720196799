
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
const vuex = new Vuex.Store({
    state:{
        model:{
          
        }   
    },
  
    mutations:{
        asyncUpdateUser(state,values) {
            state.model=values
            console.log(state.model)
    
        }
    },
  
    plugins: [createPersistedState({
        storage:window.sessionStorage  // 同localStorage相同，只是将vuex的所有值存储到sessionStorage中
    })]

})

export default vuex
